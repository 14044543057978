import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Layout from "../components/views/blog/BlogPageLayout";
import RelatedPosts from "../components/views/blog/RelatedPosts";
import { filterWithLength } from "../functions/index";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import MetaData from "../components/common/MetaData";
import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css";
import "../styles/blog.css";
import {metaData} from "../config";
const BlogPage = (props) => {
  const {
    strapiBlogs: currentBlog,
    strapiCategories: { blogs }
  } = props.data;
  const { pageContext } = props;
  const [crumbsList, setCrumbsList] = useState([]);
  useEffect(() => {
    setCrumbsList([
      { "label": "Blog", "pathName": "/blogs" },
      {
        "label": pageContext.category_slug,
        "pathName": `/topics/${pageContext.category_slug}`
      },
      {
        "label": props.data.strapiBlogs.title,
        "pathName": `/blogs/${props.data.strapiBlogs.slug}`
      }
    ]);
  }, []);
  const filterCompare = (compareData) => currentBlog.slug !== compareData.slug;
  const blogsList = filterWithLength(blogs, 2, filterCompare);
  const createdImageOne = getImage(props.data.strapiBlogs.image);
  const createdImageTwo = getImage(currentBlog.blog_author.image);
  const MyCustomBreadcrumb = ({ crumbs }) => {
    return (
      <>
        {crumbs.map((crumb, index) => {
          return (
            <>
              {index !== 2 ? (
                <>
                  <Link to={crumb.pathName} title={`${crumb.label} page link`}>
                    {crumb.label}
                  </Link>
                  {crumb.crumbSeparator}
                </>
              ) : (
                <p>{crumb.label}</p>
              )}
            </>
          );
        })}
      </>
    );
  };

  const BactToBlog = () => {
    return (
      <Link to="/blogs" title={`back to blog page link`}>
        Back To Blog
      </Link>
    );
  };
  return (
    <Layout>
      <MetaData pageTitle={props.data.strapiBlogs.title} description={metaData.blog.description} keyword={metaData.blog.keyword} />
      <section className="section singlepost p-4 overflow-hidden">
        <div className="container single-post-breadcrumb py-0">
          <div className="row justify-content-between">
            <div className="col-lg-9 pl-0 left-breadcrumb">
              <MyCustomBreadcrumb crumbs={crumbsList} />
            </div>
            <div className="col-lg-3 pr-0 text-right right-backbutton">
              <BactToBlog />
            </div>
          </div>
        </div>

        <div className="container content py-2">
          <div className="row justify-content-between mb-4">
            <div className="col-lg-9 px-0">
              <div className="single-post-cover mb-5">
                <GatsbyImage
                  image={createdImageOne}
                  alt={`${props.data.strapiBlogs.title} blog banner image`}
                />
              </div>
              <h1 className="single-title is-size-1 has-text-weight-bold is-bold-light">
                {props.data.strapiBlogs.title}
              </h1>
              <ReactMarkdown
                className="single-description"
                source={props.data.strapiBlogs.content}
              />
            </div>
            <div className="col-lg-3 rightsidebar">
              <h5 className="single-author-title my-2 mx-4">Author</h5>
              <ul className="single-author mx-4 pt-2">
                <li>
                  <GatsbyImage
                    image={createdImageTwo}
                    alt={`${currentBlog.blog_author.name} blog author image`}
                  />
                </li>
                <li>
                  <h5>{currentBlog.blog_author.name}</h5>
                  <p>{currentBlog.blog_author.role}</p>
                </li>
              </ul>
              <h4 className="single-author-read  mx-4">
                {`${currentBlog.published_at}.  ${currentBlog.readTime}`}
              </h4>
            </div>
          </div>
          {blogsList.length !== 0 ? (
            <div className="row">
              <div className="container p-0">
                <div className="content related-post pt-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h1 className="related-post-heading mb-4">
                        Related Posts
                      </h1>
                      <div className="row related-posts-container">
                        {blogsList &&
                          blogsList.map((post, index) => (
                            <RelatedPosts
                              key={index}
                              index={index}
                              post={post}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query GetBlogAndRelated($blog_slug: String, $category_slug: String) {
    strapiBlogs(slug: { eq: $blog_slug }) {
      content
      description
      slug
      title
      readTime
      published_at(formatString: "MMMM DD, YYYY")
      categories {
        updated_at(formatString: "MMMM DD, YYYY")
        slug
        title
      }
      blog_author {
        slug
        role
        name
        image {
          childImageSharp {
            gatsbyImageData(quality: 10, width: 100, placeholder: NONE)
          }
        }
      }
      image {
        childImageSharp {
          gatsbyImageData(quality: 20, width: 850, placeholder: NONE)
        }
      }
    }
    strapiCategories(slug: { eq: $category_slug }) {
      blogs {
        content
        description
        slug
        title
        created_at(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            gatsbyImageData(quality: 30, width: 350, placeholder: NONE)
          }
        }
        updated_at(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
export default BlogPage;
