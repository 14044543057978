import React from "react";
import Footer from "../../common/Footer";
import { navigate } from "gatsby";
import ScrollTop from "../../common/ScrollTop";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
const BlogLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      strapiGrootanSiteLogos {
        image {
          childImageSharp {
            gatsbyImageData(quality: 20, placeholder: NONE)
          }
        }
      }
    }
  `);
  const { image } = data.strapiGrootanSiteLogos;
  const createdImage = getImage(image);
  return (
    <div>
      <nav
        className="st-nav navbar main-nav navigation fixed-top dark-link"
        id="main-nav"
      >
        <div className="container px-0">
          <ul className="st-nav-menu nav navbar-nav justify-content-start">
            <li className="st-nav-section nav-item d-flex align-items-center">
              <Link
                to="/"
                className="tagmanager-logo-home-blogpage navbar-brand"
                title={`home page link`}
              >
                <GatsbyImage image={createdImage} alt="grootan logo" />
              </Link>
            </li>
            <li className="st-nav-section st-nav-primary nav-item d-block">
              <div
                className="tagmanager-home-blogpage-nav st-root-link nav-link font-regular cursor-pointer"
                onClick={() => {
                  navigate("/blogs");
                }}
              >
                Home
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {children}
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default BlogLayout;
