import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const RelatedPosts = ({ post, index }) => {
  const createdImage = getImage(post.image);
  return (
    <div className={`col-6 post-${index}`}>
      <article className="blog-list-item row tile is-child post-featured">
        <div className="col-lg-12 blogroll-image px-0">
          {post.image ? (
            <div className="featured-thumbnail">
              <GatsbyImage image={createdImage} alt={`${post.title} image`} />
            </div>
          ) : null}
        </div>
        <div className="col-lg-12">
          <div className="row">
            <h3 className="related-post-title px-3 py-3 mb-0">
              <Link to={"/blogs/" + post.slug} title={`${post.title} page link`}>
                {post.title}
              </Link>
            </h3>
          </div>
        </div>
      </article>
    </div>
  );
};

export default RelatedPosts;
